<template>
	<div>
		<div class="row">
			<div class="col-12 text-center">
				<img id="logo-pax logo-pax-home" class=" my-4" :src="require(`GroomyRoot/assets/img/pax.svg`)">
			</div>
		</div>
		<div class="row">
			<div class="col-12 col-md-4 mb-4 mb-md-0">
				<div class="link_pax_tool">
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">s<path d="M429.3 288.4C421.5 289.7 416 297.1 416 305v120.5L291.9 301.4c-6.248-6.25-16.38-6.352-22.63-.1016s-6.246 16.48 .002 22.73l124.1 124.1H272.8c-7.969 0-15.35 5.453-16.62 13.32C254.6 471.5 262.3 480.2 272 480.2h160c8.836 0 16-7.164 16-16v-160C448 294.5 439.4 286.8 429.3 288.4zM156.1 210.9C162.4 217.2 172.5 217.1 178.8 210.8s6.247-16.28-.001-22.53L27.91 37.48c-5.635-5.637-14.71-7-21.17-2.34C-1.5 41.08-2.184 52.64 4.688 59.51L156.1 210.9zM432 32h-160C263.2 32 256 39.16 256 48S263.2 64 272 64h121.4L4.688 452.7c-6.25 6.25-6.25 16.38 0 22.62s16.38 6.25 22.62 0L416 86.63V208C416 216.8 423.2 224 432 224S448 216.8 448 208v-160C448 39.16 440.8 32 432 32z"/></svg>
						<h3>{{ $t('pax.comparer_les_etalons') }}</h3>
						{{ $t('pax.comparer_description') }}
					</div>
					<div class="text-right">
						<router-link :to="{ name: 'pax' }">
							{{ $t('pax.lancer_pax') }} <font-awesome-icon :icon="['far', 'angle-right']" />
						</router-link>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4 mb-4 mb-md-0">
				<div class="link_pax_tool">
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M352 16V80h64c8.8 0 16 7.2 16 16s-7.2 16-16 16H352v64c0 8.8-7.2 16-16 16s-16-7.2-16-16V112H256c-8.8 0-16-7.2-16-16s7.2-16 16-16h64V16c0-8.8 7.2-16 16-16s16 7.2 16 16zM152.8 265.5c-4.7 9.5-13.7 16-24.1 17.5L41.2 295.8l63.4 61.9c7.5 7.3 11 17.9 9.2 28.3l-15 87.3L177 432.1c9.3-4.9 20.5-4.9 29.8 0L285 473.3l-15-87.3c-1.8-10.4 1.7-20.9 9.2-28.3l63.4-61.9-87.5-12.7c-10.4-1.5-19.4-8.1-24.1-17.5l-39.1-79.4-39.1 79.4zm17.6-108.1c8.8-17.9 34.3-17.9 43.1 0l46.3 94 103.5 15.1c19.7 2.9 27.5 27 13.3 40.9l-74.9 73.2 17.7 103.3c3.4 19.6-17.2 34.6-34.8 25.3l-92.6-48.8L99.3 509.2c-17.6 9.3-38.2-5.7-34.8-25.3L82.2 380.6 7.2 307.4C-7 293.5 .9 269.3 20.5 266.5l103.5-15.1 46.3-94zM448 160c8.8 0 16 7.2 16 16v32h32c8.8 0 16 7.2 16 16s-7.2 16-16 16H464v32c0 8.8-7.2 16-16 16s-16-7.2-16-16V240H400c-8.8 0-16-7.2-16-16s7.2-16 16-16h32V176c0-8.8 7.2-16 16-16z"/></svg>
						<h3>{{ $t('pax.suggestions_pax') }}</h3>
						<span v-html="getTrad('pax.suggestion_description')"></span>
					</div>
					<div class="text-right">
						<router-link :to="{ name: 'paxSuggestion' }">
							{{ $t('pax.voir_suggestions') }} <font-awesome-icon :icon="['far', 'angle-right']" />
						</router-link>
					</div>
				</div>
			</div>
			<div class="col-12 col-md-4">
				<div class="link_pax_tool">
					<div>
						<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M260.7 36.69C266.9 30.44 277.1 30.44 283.3 36.69C289.6 42.93 289.6 53.06 283.3 59.31L230.6 112H432C476.2 112 512 147.8 512 192V240C512 248.8 504.8 256 496 256C487.2 256 480 248.8 480 240V192C480 165.5 458.5 144 432 144H230.6L283.3 196.7C289.6 202.9 289.6 213.1 283.3 219.3C277.1 225.6 266.9 225.6 260.7 219.3L180.7 139.3C174.4 133.1 174.4 122.9 180.7 116.7L260.7 36.69zM331.3 372.7C337.6 378.9 337.6 389.1 331.3 395.3L251.3 475.3C245.1 481.6 234.9 481.6 228.7 475.3C222.4 469.1 222.4 458.9 228.7 452.7L281.4 400H80C53.49 400 32 421.5 32 448V496C32 504.8 24.84 512 16 512C7.164 512 0 504.8 0 496V448C0 403.8 35.82 368 80 368H281.4L228.7 315.3C222.4 309.1 222.4 298.9 228.7 292.7C234.9 286.4 245.1 286.4 251.3 292.7L331.3 372.7zM384 384C384 348.7 412.7 320 448 320C483.3 320 512 348.7 512 384C512 419.3 483.3 448 448 448C412.7 448 384 419.3 384 384zM448 416C465.7 416 480 401.7 480 384C480 366.3 465.7 352 448 352C430.3 352 416 366.3 416 384C416 401.7 430.3 416 448 416zM0 127.1C0 92.65 28.65 63.1 64 63.1C99.35 63.1 128 92.65 128 127.1C128 163.3 99.35 191.1 64 191.1C28.65 191.1 0 163.3 0 127.1zM64 159.1C81.67 159.1 96 145.7 96 127.1C96 110.3 81.67 95.1 64 95.1C46.33 95.1 32 110.3 32 127.1C32 145.7 46.33 159.1 64 159.1z"/></svg>
						<h3>{{ $t('pax.pax_inverse') }}</h3>
						{{ $t('pax.pax_inverse_description') }}
					</div>
					<div class="text-right">
						<router-link :to="{ name: 'paxReverse' }">
							{{ $t('pax.lancer_pax_inverse') }} <font-awesome-icon :icon="['far', 'angle-right']" />
						</router-link>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script type="text/javascript">

	export default {
		name: 'Home'
	}

</script>